<template>
  <div class="animated fadeIn">
    <!-- <pre>
      {{ pembelian.detail }}
    </pre> -->
    <b-card>
      <template v-slot:header>
        <h5>Detail Pembelian</h5>
      </template>
      <div class="row justify-content-between">
        <div class="col-6 col-md-3 col-lg-2">
          <img class="img-fluid" src="/img/brand/pgi_logo.png" />
        </div>
        <div class="col-6 col-md-6">
          <div>
            <div>
              <strong>Invoice : {{ pembelian.no_invoice }}</strong>
            </div>

            <div>Tanggal : {{ pembelian.tanggal }}</div>
            <div v-if="pembelian.flag === 0" class="badge badge-pill badge-secondary">Belum diproses</div>
            <div v-if="pembelian.flag === 1" class="badge badge-pill badge-warning">Sedang diproses</div>
            <div v-if="pembelian.flag === 2" class="badge badge-pill badge-primary">Sudah dibayar</div>
            <div v-if="pembelian.flag === 3" class="badge badge-pill badge-success">Diterima</div>
            <div v-if="pembelian.flag === 4" class="badge badge-pill badge-danger">Dibatalkan</div>
            <div v-if="pembelian.flag === 5" class="badge badge-pill badge-warning">Selisih</div>
            <div v-if="pembelian.flag === 6" class="badge badge-pill badge-danger">Request Void</div>
          </div>
        </div>
      </div>
      <div class="row justify-content-between mt-3">
        <div class="col-6 col-md-6 col-lg-3">
          <div><strong>From</strong></div>
          <p>JUMONO</p>
        </div>
        <div class="col-6 col-md-6">
          <div><strong>To</strong></div>
          <div>Pusat Gadai Indonesia</div>
          <div>Jl. Arteri Klapa Dua Kav.12A, Kebon Jeruk</div>
        </div>
      </div>
      <hr>
      <div class="row justify-content-between">
        <div class="col-lg-6 col-xl-4">
          <div class="keyval">
            <div class="key">TOTAL PEMBELIAN</div>
            <div class="val">
              <div class="keyval">
                <div class="key">Rp</div>
                <div class="val">
                  <b>{{ $helper.rupiah(pembelian.total_pembelian || 0) }}</b>
                </div>
              </div>
            </div>
          </div>
          <div class="keyval">
            <div class="key">ONGKOS KIRIM</div>
            <div class="val">
              <div class="keyval">
                <div class="key">Rp</div>
                <div class="val">
                  <b>{{ $helper.rupiah(pembelian.ongkir || 0) }}</b>
                </div>
              </div>
            </div>
          </div>
          <div class="keyval border-top mt-2">
            <div class="key">GRAND TOTAL</div>
            <div class="val">
              <div class="keyval">
                <div class="key">Rp</div>
                <div class="val">
                  <b>{{ $helper.rupiah(parseInt(pembelian.total_pembelian) + pembelian.ongkir || 0) }}</b>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div class="col-lg-6">
          <template v-if="pembelian.is_online === 2">
            <div class="keyval">
              <div class="key">Supplier</div>
              <div class="val">
                {{ pembelian.supplier.nama }}
              </div>
            </div>
            <div class="keyval">
              <div class="key">No. Tlp</div>
              <div class="val">
                {{ pembelian.supplier.no_hp }}
              </div>
            </div>
            <div class="keyval">
              <div class="key">Nama Rekening</div>
              <div class="val">{{ pembelian.supplier.nama_rekening }}</div>
            </div>
            <div class="keyval">
              <div class="key">Rekening</div>
              <div class="val">{{ pembelian.supplier.bank.nama_bank || "" }} / {{ pembelian.supplier.no_rekening }}</div>
            </div>
          </template>
          <template v-if="pembelian.is_online === 1">
            <div class="keyval">
              <div class="key">Supplier</div>
              <div class="val">
                {{ pembelian.supplier.nama }}
              </div>
            </div>
            <div class="keyval">
              <div class="key">Merchant</div>
              <div class="val">
                {{ pembelian.shop_name || "-" }}
              </div>
            </div>
            <div class="keyval">
              <div class="key">Link Merchant</div>
              <div class="val">
                {{ pembelian.link_merchant || "-" }}
              </div>
            </div>
          </template>
        </div>
      </div>
      <div class="row my-4">
        <div class="col-lg-3">
          <div class="mb-2">Bukti Pembelian</div>
          <div class="file-wrapper">
            <div v-for="item in files_invoice" :key="item.id" class="file-item-box">
              <expandable-image v-if="item.file_type === 'image'" class="img-item" :src="item.path" alt="al" />
              <button class="btn btn-sm btn-primary" @click="previewFile(item.path)" v-if="item.file_type === 'pdf'"
                target="_blank" rel="noopener noreferrer">
                <i class="fa fa-file-pdf-o"></i>
                <small class="ml-1">PDF</small>
              </button>
            </div>
          </div>
        </div>
        <div class="col-lg-3">
          <div class="mb-2">Bukti Pembayaran</div>
          <div class="file-wrapper">
            <div v-for="item in files_bukti_pembayaran" :key="item.id" class="file-item-box">
              <expandable-image v-if="item.file_type === 'image'" class="img-item" :src="item.path" alt="al" />
              <button class="btn btn-sm btn-primary" @click="previewFile(item.path)" v-if="item.file_type === 'pdf'"
                target="_blank" rel="noopener noreferrer">
                <i class="fa fa-file-pdf-o"></i>
                <small class="ml-1">PDF</small>
              </button>
            </div>
          </div>
        </div>
      </div>
      <ActionPembelianDetailFinance :data="pembelian" />
    </b-card>

    <b-modal id="m-preview-file" size="xl" centered hide-footer title="Detail">
      <iframe width="100%" style="height: 80vh;" :src="previewFileItem" frameBorder="0" scrolling="auto"></iframe>
    </b-modal>
  </div>
</template>

<script>
import ActionPembelianDetailFinance from "../../../components/pembelian/actionPembelianDetailFinance.vue"
export default {
  components: { ActionPembelianDetailFinance },
  data() {
    return {
      isLoading: false,
      files_invoice: [],
      files_bukti_pembayaran: [],
      previewFileItem: null,
      total_harga: 0
    }
  },
  methods: {
    async getStorePembelian() {
      const response = await this.$store.dispatch("getDetailPembelian", this.$route.params.id)
      this.files_invoice = response.files_invoice.map(item => {
        const ext = item.path.split(".").pop()
        return {
          file_type: ext === 'pdf' ? 'pdf' : 'image',
          ...item
        }
      })
      this.files_bukti_pembayaran = response.files_bukti_pembayaran.map(item => {
        const ext = item.path.split(".").pop()
        return {
          file_type: ext === 'pdf' ? 'pdf' : 'image',
          ...item
        }
      })
      const arrayHarga = response.detail.map(item => {
        return item.total_harga
      })
      this.total_harga = arrayHarga.reduce((accumulator, current) => {
        return accumulator + current;
      }, 0);
    },
    previewFile(value) {
      // m-preview-file
      this.previewFileItem = value
      this.$bvModal.show("m-preview-file");
    }
  },
  computed: {
    pembelian() {
      return this.$store.state.pembelian.pembelian_list
    },
  },
  mounted() {
    this.$store.dispatch("getHistoryApproval", this.$route.params.id)
    this.getStorePembelian()
  }
}
</script>
