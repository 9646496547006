<template>
  <b-modal id="m-pin" title="Verifikasi Pin" centered :no-close-on-backdrop="isSubmitting" hide-footer
    :no-close-on-esc="isSubmitting" :hide-header-close="isSubmitting" @shown="focusBtnSubmit">
    <input-otp-vue />
    <template v-slot:modal-footer="{ ok, cancel }">
      <b-button variant="secondary" name="cancelSubmitBtn" @click="cancel()" :disabled="isSubmitting">
        Cancel
      </b-button>
      <b-button variant="pgiBtn" ref="btnSubmit" name="okeSubmitBtn" @click="$emit('submit')" :disabled="isSubmitting">
        <b-spinner small type="grow" v-if="isSubmitting"></b-spinner>
        {{ isSubmitting ? "Memproses..." : "Ya" }}
      </b-button>
    </template>
  </b-modal>
</template>
<script>
import InputOtpVue from "./InputOtp";

export default {
  name: "ModalPin",
  components: {
    InputOtpVue,
  },
  props: {
    id: { type: String },
    title: { type: String },
    desc: { type: String },
    isSubmitting: { type: Boolean },
    alertMessage: { type: String, default: "" },
    boldMessage: { type: String, default: "" },
  },
  methods: {
    focusBtnSubmit() {
      this.$refs.btnSubmit.focus();
    },
  },
};
</script>
