<template>
    <div>
        <template v-if="role === 'Finance'">
            <div class="d-flex justify-content-end">
                <b-button v-if="data.status_approval === 5" @click="$bvModal.show('m-pin')" variant="pgiBtn" class="mr-2">
                    <i class="fa fa-money"></i>
                    Proses Pembayaran
                </b-button>
                <b-button @click="uploadBuktiPembayaran" v-if="data.status_approval === 6 && data.flag === 1"
                    variant="pgiBtn" class="mr-2">
                    <i class="fa fa-upload"></i>
                    Upload Bukti
                </b-button>
                <b-button v-if="data.status_approval > 2" @click="$bvModal.show('m-history-approve')" variant="pgiBtn"
                    class="mr-2">
                    <i class="fa fa-history"></i>
                    History Approval
                </b-button>
            </div>
        </template>
        <b-table small bordered hover show-empty :isBusy="isLoading" :items="data.detail" :fields="fields" responsive
            class="my-3">
            <template v-slot:table-busy>
                <div class="text-center text-danger my-2">
                    <b-spinner class="align-middle"></b-spinner>
                    <strong>Loading data...</strong>
                </div>
            </template>
            <template v-slot:empty> Data tidak ditemukan! </template>
            <template v-slot:cell(cabang)="row">
                {{ row.item.cabang || "PUSAT" }}
            </template>
            <template v-slot:cell(nomer_barang)="row">
                <div>
                    <span class="d-block">Kode : {{ row.item.tipe_barang.kode_barang }}</span>
                    <small class="d-block">Nama : {{ row.item.tipe_barang.tipe }}</small>
                </div>
            </template>
            <template v-slot:cell(jumlah)="row">
                <div>
                    <b class="mr-1">{{ row.item.jumlah }}</b>
                    <small>{{ row.item.satuan }}</small>
                </div>
            </template>
            <template v-slot:cell(harga)="row">
                <div class="d-flex align-items-center justify-content-between">
                    <div>Rp.</div>
                    <div class="text-end">{{ $helper.rupiah(row.item.harga || 0) }}</div>
                </div>
            </template>
            <template v-slot:cell(total_harga)="row">
                <div class="d-flex align-items-center justify-content-between">
                    <div>Rp.</div>
                    <div class="text-end">{{ $helper.rupiah(row.item.total_harga || 0) }}</div>
                </div>
            </template>

            <template v-slot:head(action)="row">
                <div class="text-right">
                    Aksi
                </div>
            </template>
        </b-table>

        <!-- MODAL HISTORY APPROVE -->
        <b-modal id="m-history-approve" hide-footer title="History Approval">
            <ul class="list-group list-group-flush">
                <li v-for="(item, index) in history_approval"
                    class="list-group-item d-flex justify-content-between align-items-center">
                    <div class="d-flex">
                        <div class="mr-2">{{ index + 1 }}.</div>
                        <div>
                            <div>{{ item.keterangan }}</div>
                            <small>Pada {{ $moment(item.created_at).format('DD MMM YYYY, h:mm:ss') }}</small>
                        </div>
                    </div>
                    <span class="badge bg-success rounded-pill">
                        <i class="fa fa-check"></i>
                    </span>
                </li>
            </ul>
        </b-modal>

        <ModalPin></ModalPin>

        <b-modal hide-footer centered title="Upload Bukti Invoice" id="m-modal-bukti-bayar">

            <div class="row">
                <div class="col-3 col-md-4 mb-3" v-for="item in uploadFilePreview" :key="item.preview">
                    <div class="image-preview-container">
                        <!-- @click="removePreviewImage(item)" -->
                        <button type="button" @click="removePreviewImage(item.preview, item.file)" class="btn-remove-image">
                            <i class="fa fa-times"></i>
                        </button>
                        <expandable-image v-if="item.file.type.includes('image')" class="image-preview"
                            :src="item.preview" />
                        <div v-if="item.file.type.includes('pdf')"
                            class="image-preview file-or-pdf d-flex justify-content-center align-items-center">
                            <i class="fa fa-file-pdf-o"></i>
                        </div>
                    </div>
                </div>
                <div class="col-3 col-md-4 mb-3">
                    <label for="images" class="choose-image-action">
                        <input multiple @change="handleChangeImages" type="file" accept=".jpg, .png, .pdf" id="images"
                            class="d-none">
                        <i class="fa fa-plus icon-choose"></i>
                    </label>
                </div>
            </div>
            <div class="d-flex justify-content-end mt-3">
                <b-button @click="handleSubmitBuktiBayar" :disabled="isLoading" variant="pgiBtn" type="submit">
                    <template v-if="!isLoading">
                        <i class="fa fa-upload"></i> Upload
                    </template>
                    <template v-if="isLoading">
                        <i class="fa fa-spin fa-spinner"></i> Uploading...
                    </template>
                </b-button>
            </div>
        </b-modal>
    </div>
</template>

<script>
import { mapState } from 'vuex'
import ModalPin from '../otp/ModalPin.vue';
import axiosClient from '../../config/api';
export default {
    props: ['data'],
    components: { ModalPin },
    data() {
        return {
            fields: [
                { key: "cabang", label: "Cabang" },
                { key: "nomer_barang", label: "Info Barang" },
                { key: "jumlah", label: "Jumlah" },
                { key: "harga", label: "Harga" },
                { key: "total_harga", label: "Total Harga" }
            ],
            uploadFilePreview: [],
            uploadFiles: [],
            formBuktiBayar: {
                id_pembelian: null,
                file: null
            },
            file_single_cabang: null,
            file_multi_cabang: null,
            role: localStorage.getItem('role'),
        };
    },
    methods: {
        async dispatchPembelian() {
            await this.$store.dispatch("getDetailPembelian", this.$route.params.id);
        },

        uploadBuktiPembayaran() {
            this.$bvModal.show('m-modal-bukti-bayar')
        },

        handleChangeImages(e) {
            // this.fileErrorMessage = ''
            const files = e.target.files;
            this.createPreviewImage(files)
        },

        createPreviewImage(files) {
            for (let i = 0; i < files.length; i++) {
                const file = files[i];
                this.uploadFiles.push(file)
                let blobURL = URL.createObjectURL(file);
                this.uploadFilePreview.push({
                    preview: blobURL,
                    file: file
                })
                this.formBuktiBayar.file = this.uploadFiles;
            }
        },

        removePreviewImage(preview, file) {
            const newPreview = this.uploadFilePreview.filter(i => i.preview !== preview)
            this.uploadFilePreview = newPreview
            const newFile = this.uploadFiles.filter(i => i.name !== file.name)
            this.uploadFiles = newFile
            this.formBuktiBayar.file = this.uploadFiles;
        },

        async handleSubmitBuktiBayar() {
            const files = this.formBuktiBayar.file;

            try {
                let form = new FormData()
                form.append('id_pembelian', this.$route.params.id)
                for (let i = 0; i < files.length; i++) {
                    const file = files[i];
                    console.log(file)
                    form.append(`file[${i}]`, file)
                }
                const response = await axiosClient({
                    method: 'POST',
                    url: 'transaksi/pembelian/upload-bukti-pembayaran',
                    data: form
                })
                if (response.status === 200) {
                    this.dispatchPembelian()
                    this.$store.dispatch("getHistoryApproval", this.$route.params.id)
                    this.$bvModal.hide("m-modal-bukti-bayar");
                }
            } catch (error) {
                console.log(error)
            }
        },
        handleSelectFileBuktiBayar(value) {
            const files = value.target.files
            this.formBuktiBayar.file = files;
        },
    },
    computed: {
        ...mapState("loading", ["isLoading"]),
        history_approval() {
            return this.$store.state.pembelian.history_approval;
        }
    }
}
</script>