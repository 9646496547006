<template>
  <div class="boxPin" style="display: flex; justify-content: center">
    <div style="display: flex; flex-direction: row">
      <v-otp-input ref="otpInput" input-classes="otp-input" separator="-" :num-inputs="6" :should-auto-focus="true"
        :is-input-num="true" @on-change="handleOnChange" @on-complete="handleOnComplete" />
    </div>
    <div v-if="isLoading" class="loaderPin">
      <i class="fa fa-spin fa-spinner"></i>
    </div>
  </div>
</template>

<script>
import axiosClient from '../../config/api';

export default {
  name: "InputOtp",

  data() {
    return {
      idPembelian: null,
      isLoading: false
    }
  },
  methods: {
    async handleOnComplete(value) {
      console.log("OTP completed: ", value);
      try {
        this.isLoading = true
        const response = await axiosClient({
          method: "POST",
          url: 'transaksi/pembelian/approve-pembelian',
          data: {
            id_pembelian: this.idPembelian,
            pin: value
          }
        })
        if (response.status === 200) {
          this.$helper.toastSucc(this, "Pembayaran berhasil")
          await this.$store.dispatch("getDetailPembelian", this.$route.params.id);
          this.$bvModal.hide('m-pin')

        }
      } catch (error) {
        this.$helper.toastErr(this, "Pin Anda Salah!")
        console.log(error)
      } finally {
        this.isLoading = false
      }
    },
    handleOnChange(value) {
      // console.log("OTP changed: ", value);
    },
    handleClearInput() {
      this.$refs.otpInput.clearInput();
    },
  },
  created() {
    this.idPembelian = this.$route.params.id
  }
};
</script>

<style lang="scss">
.otp-input {
  width: 40px;
  height: 40px;
  padding: 5px;
  margin: 0 10px;
  font-size: 20px;
  border-radius: 4px;
  border: 1px solid rgba(0, 0, 0, 0.3);
  text-align: center;

  &.error {
    border: 1px solid red !important;
  }
}

.otp-input::-webkit-inner-spin-button,
.otp-input::-webkit-outer-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

.boxPin {
  position: relative;
}

.loaderPin {
  position: absolute;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  display: flex;
  justify-content: center;
  align-items: center;
}
</style>
